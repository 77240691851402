
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import 'localforage-getitems';

const VerifyEmailBanner = () =>
	import(/* webpackChunkName: "verify-email-banner" */ '@/components/modals/account/VerifyEmailBanner.vue');

import { UserInfo as StoreUserInfo } from '@/stores/user.store';
import { LoginProvider } from '@/enums/login';

const User = namespace('user');

@Component({
	name: 'VerifyEmailDataProvider',
	components: { VerifyEmailBanner },
})
export default class VerifyEmailDataProvider extends Vue {
	showBanner = false;

	@User.Action loadInfo: () => void;
	@User.Getter userInfo: StoreUserInfo;
	@User.Getter isLoggedIn: () => boolean;

	@Watch('userLoggedIn', { immediate: true })
	async onUserLoggedIn(isLoggedIn: boolean) {
		await this.loadInfo();

		if (!isLoggedIn || this.userEmailVerified) {
			this.showBanner = false;
		}

		if (isLoggedIn && this.isAuthenticatedWithEmail && !this.userEmailVerified && !this.showBanner) {
			this.showBanner = true;
		}
	}

	get userLoggedIn(): boolean {
		return this.isLoggedIn();
	}

	get userEmailVerified() {
		return this.userInfo.emailVerified;
	}

	get isAuthenticatedWithEmail() {
		return this.userInfo.loginProvider === LoginProvider.JUSTWATCH;
	}

	get userCreationTime() {
		return this.userInfo.creationTime;
	}
}
